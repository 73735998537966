<script setup lang="ts">
type Variant = "glass" | "inherit" | "transparent" | "table" | "outline";
type Size = "base" | "lg";

const {
  icon = "home",
  variant = "inherit",
  size = "base",
  number = 0,
} = defineProps<{
  icon?: string;
  variant?: string;
  size?: string;
  number?: number;
}>();

const variants: Record<Variant, { style: string }> = {
  glass: {
    style:
      "bg-gray-100/80 text-gray-700 hover:bg-gray-200/50 hover:text-gray-800 dark:hover:bg-primary-500/50",
  },
  inherit: {
    style: "hover:bg-gray-200/20",
  },
  transparent: {
    style: "bg-transparent text-gray-200 hover:bg-gray-200/50 dark:text-white",
  },
  table: {
    style:
      "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400 hover:bg-gray-200/50 dark:text-white",
  },
  outline: {
    style:
      "bg-white text-gray-800 dark:text-white ring-1 hover:bg-gray-100  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 disabled:bg-transparent disabled:text-gray-400 disabled:ring-gray-400",
  },
};

const sizes: Record<Size, string> = {
  base: "h-[38px] w-[38px] text-sm",
  lg: "h-[44px] w-[44px] text-base",
};
</script>

<template>
  <div :class="variant === 'outline' && 'p-[1px] bg-gradient-1 rounded-full'">
    <div
      class="relative flex cursor-pointer items-center justify-center rounded-full transition"
      :class="[variants[variant as Variant].style, sizes[size as Size]]"
    >
      <i
        :class="`ri-fw ri-${icon} ${
          variant === 'outline' ? 'text-2xl' : 'text-base'
        }`"
      />
      <div
        v-if="number > 0"
        class="absolute bottom-0 right-0 rounded-full border border-white bg-primary-500 px-[6px] py-[4px] text-[10px] font-bold leading-[9px] text-white text-gray-"
      >
        {{ number }}
      </div>
    </div>
  </div>
</template>
